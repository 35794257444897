/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 22, 2021 */
@font-face {
  font-family: "Gilroy";
  src: url("../assets/fonts/Gilroy/radomir_tinkov_-_gilroy-medium-webfont.eot");
  src: url("../assets/fonts/Gilroy/radomir_tinkov_-_gilroy-medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Gilroy/radomir_tinkov_-_gilroy-medium-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/Gilroy/radomir_tinkov_-_gilroy-medium-webfont.woff")
      format("woff"),
    url("../assets/fonts/Gilroy/radomir_tinkov_-_gilroy-medium-webfont.svg#gilroymedium")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/fonts/Gilroy/radomir_tinkov_-_gilroy-semibold-webfont.eot");
  src: url("../assets/fonts/Gilroy/radomir_tinkov_-_gilroy-semibold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Gilroy/radomir_tinkov_-_gilroy-semibold-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/Gilroy/radomir_tinkov_-_gilroy-semibold-webfont.woff")
      format("woff"),
    url("../assets/fonts/Gilroy/radomir_tinkov_-_gilroy-semibold-webfont.svg#gilroysemibold")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/fonts/Gilroy/radomir_tinkov_-_gilroy-bold-webfont.eot");
  src: url("../assets/fonts/Gilroy/radomir_tinkov_-_gilroy-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Gilroy/radomir_tinkov_-_gilroy-bold-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/Gilroy/radomir_tinkov_-_gilroy-bold-webfont.woff")
      format("woff"),
    url("../assets/fonts/Gilroy/radomir_tinkov_-_gilroy-bold-webfont.svg#gilroybold")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}
