.hero {
  position: relative;
  z-index: 2;
  padding-top: 8rem;
  min-height: 30rem;
  padding-bottom: 5rem;
  @media screen and (min-width: 575px) {
    min-height: 45rem;
    padding-top: 15rem;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 18rem;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 21rem;
  }
  &__intro {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media screen and (min-width: 700px) {
      justify-content: space-between;
    }
  }
  &__title {
    font-size: 2.5rem;
    color: $hero-font-color;
    line-height: 1.25;
    font-weight: 700;
    @media screen and (min-width: 700px) {
      font-size: 3.2rem;
      line-height: 1.16;
      max-width: 52%;
    }
    @include media-breakpoint-up(lg) {
      font-size: 4rem;
    }
    @include media-breakpoint-up(xl) {
      text-align: left;
      max-width: 73rem;
      font-size: 6rem;
    }
    br {
      display: none;
      @media screen and (min-width: 700px) {
        display: inline;
      }
    }
  }
  &__text {
    margin-top: 2rem;
    max-width: 80%;
    font-size: 1.8rem;
    line-height: 1.55;
    color: $hero-font-color;
    text-align: center;
    display: none;
    @media screen and (min-width: 700px) {
      display: block;
      margin-top: 0;
      text-align: right;
      max-width: 48%;
    }
    @include media-breakpoint-up(xl) {
      max-width: 32.7rem;
    }
    &--mobile {
      color: $main-font-color;
      text-align: center;
      margin: 3rem auto;
      @media screen and (min-width: 700px) {
        display: none;
      }
    }
  }
  .movie-navigation {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0 2rem 0;
    @media screen and (min-width: 700px) {
      margin: 3.5rem 0 2rem 0;
    }
    @media screen and (min-width: 1920px) {
      margin: 5.5rem 0 8rem 0;
    }
  }
  .play-btn-wrapper {
    width: auto;
    @include media-breakpoint-up(lg) {
      width: 30%;
    }
  }
  .line {
    position: relative;
    display: none;
    opacity: 0.7;
    width: 50%;
    height: 1px;
    background-color: $primary;
    animation: drawingLine 2s;
    @media screen and (min-width: 700px) {
      display: block;
    }
    @include media-breakpoint-up(lg) {
      width: 70%;
    }
  }
  &__boxes {
    margin-bottom: -5rem;
    margin-top: 7.7rem;
    position: relative;
    z-index: 2;
    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }
    &--mobile {
      display: none;
      margin-top: 0;
      @include media-breakpoint-up(md) {
        display: flex;
        margin-top: -5rem;
      }
    }
    &--desktop {
      display: none;
      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }
    &-item {
      display: flex;
      padding: 2.5rem;
      min-height: 20rem;
      margin-bottom: -5rem;
      background-color: $box-solor;
      border-radius: 35px;
      @include media-breakpoint-up(lg) {
        padding: 4.5rem 3.5rem;
      }
      &:hover {
        text-decoration: none;
        background-color: darken($box-solor, 5%);
        .hero__boxes-item-arrow {
          margin-left: 10px;
        }
      }
      p {
        margin-top: 1rem;
        margin-bottom: 2.5rem;
        max-width: 23.3rem;
        color: $hero-font-color;
        font-size: 2rem;
        font-weight: 700;
      }
    }
    &-item-title {
      font-size: 1.5rem;
      font-weight: 600;
      color: $secondary;
    }
    &-item-icon {
      display: inline-flex;
      align-items: flex-start;
      margin-right: 2rem;
      img {
        width: 4rem;
        height: 4rem;
        object-fit: contain;
      }
    }
    &-item-arrow {
      display: inline-block;
      width: 12px;
      height: 12px;
      transition: $transition-time;
    }
  }
  .video-section {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    overflow: hidden;
    background-image: url(../img/hero-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
      opacity: 0.25;
      background-color: $main-font-color;
    }
  }
  .youtube-wrapper {
    position: relative;
    position: relative;
    top: -50%;
    left: -25%;
    width: 150%;
    height: 200%;
    @media screen and (min-width: 700px) {
      top: -12%;
      left: -20%;
      width: 150%;
      height: 142%;
    }
    @include media-breakpoint-up(lg) {
      top: -20%;
      left: -26%;
    }
    .hero-youtube {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .pause-btn {
    position: absolute;
    top: 8rem;
    right: 2rem;
    z-index: 10;
    width: 4rem;
    height: 4rem;
    opacity: 0;
    background-color: $secondary;
    border: none;
    border-radius: 50%;
    background-image: url(../img/icon-close.svg);
    background-size: 12px;
    background-position: center;
    background-repeat: no-repeat;
    color: $hero-font-color;
    @include media-breakpoint-up(md) {
      top: 15rem;
      right: 5rem;
    }
  }
}
.play-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;
  color: $hero-font-color;
  font-weight: 600;
  font-size: 1.5rem;
  @media screen and (min-width: 700px) {
    margin-left: 5rem;
  }
  &:hover {
    .play-btn-img {
      background-color: $main-font-color;
    }
  }
}
.play-btn-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 2px solid $primary;
  border-radius: 50%;
  margin-left: 2.8rem;
  transition: $transition-time;
  @include media-breakpoint-up(md) {
    width: 60px;
    height: 60px;
  }
  @include media-breakpoint-up(lg) {
    width: 82px;
    height: 82px;
  }
  img {
    width: 10px;
    height: 10px;
    @include media-breakpoint-up(md) {
      width: 15px;
      height: 15px;
    }
    @include media-breakpoint-up(lg) {
      width: 22px;
      height: 22px;
    }
  }
}
.about {
  position: relative;
  padding-top: 5rem;
  @include media-breakpoint-up(md) {
    padding-top: 15rem;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 20rem;
  }
}
.about-img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-top: 5rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
    margin-top: 0;
  }
  &--wider {
    max-width: 100%;
    @media screen and (min-width: 700px) {
      position: relative;
      left: -11rem;
      max-width: calc(100% + 11rem);
    }
  }
}
.about-content {
  @include media-breakpoint-up(md) {
    padding-left: 5rem;
    &--left {
      padding-right: 5rem;
    }
  }
  .section-title {
    text-align: center;
    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }
}
.row--about {
  margin-bottom: 9.5rem;
}
.counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;
  @include media-breakpoint-up(md) {
    margin-bottom: 8rem;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
    margin-bottom: 0;
    border-top: 1px solid $textlight2;
  }
  &__number,
  &__years {
    display: inline-block;
    margin-bottom: 2.5rem;
    font-size: 4rem;
    font-weight: 600;
    color: $primary;
    @include media-breakpoint-up(md) {
      font-size: 6rem;
    }
  }
  &__number {
    visibility: hidden;
    &.counter-visible {
      visibility: visible;
    }
  }
  &__caption {
    color: $textlight2;
    font-size: 1.5rem;
    font-weight: 500;
  }
}
.extra-carousel {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    background-color: $bcg-dark2;
    background-position: center;
    background-size: 12px;
    background-repeat: no-repeat;
    color: transparent;
  }
  .slick-prev {
    left: 0;
    background-image: url(../img/icon-prev.svg);
  }
  .slick-next {
    right: 0;
    background-image: url(../img/icon-next.svg);
  }
  .slick-track {
    display: flex !important;
  }
  .slick-list {
    margin: 0 -10px;
  }
  .slick-slide {
    height: inherit !important;
    margin: 0 10px;
  }
}
.extra-box {
  background-color: $hero-font-color;
  border-radius: 20px;
  padding: 3.5rem;
  border: 2px solid $border-light;
  @media screen and (min-width: 1799px) {
    padding: 5rem;
  }
  &__top {
    display: flex;
    @include media-breakpoint-up(sm) {
      display: block;
    }
  }
  &__icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-bottom: 2.5rem;
    margin-right: 1.5rem;
    @include media-breakpoint-up(sm) {
      margin-right: 0;
      height: 45px;
      width: 45px;
    }
  }
  &__title {
    margin-bottom: 3.5rem;
    font-size: 2.2rem;
    font-weight: 600;
    color: $primary;
    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
    }
  }
  &__btn {
    margin-top: 7.7rem;
    background-color: $textlight2;
    color: $hero-font-color;
  }
}
.studio-e {
  position: relative;
  padding-top: 4.5rem;
  @include media-breakpoint-up(md) {
    padding-top: 14.5rem;
  }
  .img-studio {
    min-height: 25rem;
    object-fit: cover;
    margin-top: 3rem;
    display: inline-block;
    @include media-breakpoint-up(md) {
      min-height: 35rem;
    }
    @include media-breakpoint-up(lg) {
      min-height: 40rem;
      margin-top: 0;
    }
    @media screen and (min-width: 1799px) {
      min-height: 45.2rem;
    }
  }
}
.clients {
  padding-top: 11.5rem;
  position: relative;
}
.opinions-carousel {
  position: relative;
  margin-bottom: 7.5rem;
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    width: 40px;
    height: 40px;
    background-color: $hero-font-color;
    border: 2px solid $border-light;
    border-radius: 50%;
    color: transparent;
    background-position: center;
    background-size: 12px;
    background-repeat: no-repeat;
    &:hover {
      border-color: $secondary;
    }
  }
  .slick-prev {
    left: 0;
    background-image: url(../img/icon-prev-black.svg);
    @include media-breakpoint-up(md) {
      left: -2rem;
    }
  }
  .slick-next {
    right: 0;
    background-image: url(../img/icon-next-black.svg);
    @include media-breakpoint-up(md) {
      right: -2rem;
    }
  }
  .quote {
    position: relative;
    padding: 0 3rem;
    @include media-breakpoint-up(md) {
      padding: 0 12rem;
    }
    @media screen and (min-width: 1560px) {
      padding: 0 24rem;
    }

    &:before {
      content: url(../img/quote.svg);
      position: absolute;
      display: block;
      left: 2rem;
      top: 10px;
      width: 35px;
      height: 20px;
      @include media-breakpoint-up(md) {
        left: 5rem;
      }
      @include media-breakpoint-up(xl) {
        left: 12rem;
      }
    }
    &:after {
      content: url(../img/quote.svg);
      display: block;
      position: absolute;
      right: 2rem;
      top: 10px;
      width: 35px;
      height: 20px;
      @include media-breakpoint-up(md) {
        right: 5rem;
      }
      @include media-breakpoint-up(xl) {
        right: 12rem;
      }
    }
    &__text {
      font-size: 1.8rem;
      line-height: 1.4;
      text-align: center;
      color: #494850;
      font-weight: 600;
      font-style: italic;
      padding-left: 2rem;
      padding-right: 2rem;
      @include media-breakpoint-up(md) {
        font-weight: unset;
        font-size: 2rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 2.5rem;
      }
    }
    &__author {
      display: block;
      margin-top: 2.2rem;
      font-weight: 600;
      color: $primary;
      font-size: 1.5rem;
      font-style: normal;
    }
  }
}
.logo-carousel {
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 7rem;
  }
  .slick-track {
    display: flex;
    .slick-slide {
      display: flex;
      height: auto;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
  }
  .logo-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    img {
      height: 45px;
      width: 120px;
      object-fit: contain;
      opacity: 0.5;
      transition: 0.2s;
      @include media-breakpoint-up(lg) {
        height: 70px;
        width: 160px;
      }
    }
    &:hover {
      img {
        opacity: 1;
      }
    }
  }
}
.functionality {
  position: relative;
  &__background {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: calc(100% - 20rem);
      background-color: #f8f8fa;
    }
  }
  .top-header__navigation {
    background-color: #f8f8fa;
  }
  .top-header__menu-list {
    li {
      a {
        color: $textlight3;
        font-weight: 600;
        &.functional-header__current {
          color: $main-font-color;
        }
      }
    }
  }
  .functionality__header {
    display: none;
    position: sticky;
    z-index: 5;
    padding-top: 2px;
    padding-bottom: 2.2rem;
    transition: $transition-time;
    background-color: #f8f8fa;
    @include media-breakpoint-up(lg) {
      display: block;
      top: 7.8rem;
    }
    .logo {
      &-white {
        display: none;
      }
    }
    &.is-pinned {
      transition: $transition-time;
      background-color: $primary !important;

      .progress-container {
        background-color: #5c80fa;
        .progress-bar {
          background-color: $hero-font-color;
        }
      }
      .btn {
        color: $primary;
        background-color: $hero-font-color;
      }
      .number-list {
        li {
          a {
            color: $hero-font-color;
            border-color: $hero-font-color;
            &:hover,
            &.active {
              background-color: $hero-font-color;
              color: $primary;
            }
          }
        }
      }
      .titles {
        .scroll-icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: $hero-font-color;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            path {
              fill: $primary;
            }
          }
        }
        p {
          color: $hero-font-color;
        }
      }
    }
  }
  .title-mobile {
    text-align: center;
    background-color: #f8f8fa;
    padding-top: 8rem;
    margin-bottom: 0;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}
.titles {
  display: flex;
  justify-self: flex-start;
  align-items: center;
  @include media-breakpoint-up(lg) {
    width: 30%;
    justify-self: flex-start;
    align-items: center;
  }
  p {
    color: $primary;
    font-size: 1.5rem;
    font-weight: 600;
    width: calc(100% - 40px);
    padding-left: 10px;
  }
  .scroll-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      path {
        fill: white;
      }
    }
  }
}
.funct-wrapper {
  margin-top: 3rem;
  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
}
.function-row {
  position: relative;
  padding-top: 5rem;
  @include media-breakpoint-up(md) {
    padding-top: 8rem;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 15rem;
  }
  .section-title {
    font-size: 2.2rem;
    @include media-breakpoint-up(sm) {
      font-size: 3rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 5rem;
    }
  }
}
.function-option {
  position: relative;
}
.inside-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.progress-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 2px;
  background-color: #e6e6ef;
  margin-top: 1rem;
  margin-bottom: 1rem;
  @include media-breakpoint-up(lg) {
    width: 35%;
    margin: 0;
  }
  .progress-bar {
    position: absolute;
    left: 0;
    display: block;
    height: 100%;
    width: 12.5%;
    background-color: $primary;
  }
}
.number-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  @include media-breakpoint-up(lg) {
    justify-content: flex-end;
    width: 35%;
  }
  li {
    margin: 0 0.2rem;
    @include media-breakpoint-up(md) {
      margin: 0 0.5rem;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.4;
      width: 3rem;
      height: 3rem;
      color: #bdbdc7;
      border: 2px solid #e6e6ef;
      border-radius: 50%;
      font-size: 1.5rem;
      font-weight: 600;
      @media screen and (min-width: 1560px) {
        width: 4rem;
        height: 4rem;
      }
      &:hover {
        opacity: 1;
        background-color: $primary;
      }
    }
  }
}
.animation-lottie {
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
  @include media-breakpoint-up(lg) {
    max-width: 80%;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @include media-breakpoint-up(xl) {
    max-width: 90%;
  }
}
.ill-col {
  position: relative;
  overflow: visible;
}
#popup {
  display: none;
}
.popup-wrapper {
  position: fixed;
  z-index: 9998;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .popup-overlay {
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($main-font-color, 0.5);
  }
  .popup {
    position: relative;
    overflow: auto;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 10;
    width: 40px;
    height: 40px;
    background-image: url(../img/icon-close.svg);
    background-size: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px;
    border: none;
    border-radius: 50%;
    color: $hero-font-color;
    z-index: 5;
    background-color: $textlight2;
    &:hover {
      background-color: $primary;
    }
    @include media-breakpoint-up(md) {
      top: 2rem;
      right: 2rem;
    }
  }
}
.custom-navigation {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  .custom-prev,
  .custom-next {
    bottom: 2rem;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    background-color: $hero-font-color;
    svg {
      width: 10px;
      height: 10px;
      path {
        fill: $main-font-color;
      }
    }
    &:hover {
      background-color: $primary;
      svg {
        path {
          fill: $hero-font-color;
        }
      }
    }
  }
  .custom-prev {
    margin-right: 3rem;
  }
  .custom-next {
    margin-left: 3rem;
  }
}
.cta-slider {
  position: relative;
  padding-bottom: 6rem;
  .slick-list {
    overflow: visible;
  }
  .slick-slide {
    opacity: 1;
    height: 100% !important;
    max-height: 70rem;
    position: relative;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    @include media-breakpoint-up(md) {
      margin-left: 3rem;
      margin-right: 3rem;
    }
    @include media-breakpoint-up(lg) {
      margin-left: 6rem;
      margin-right: 6rem;
    }
    scrollbar-color: rgba($main-font-color, 0.2);
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      display: block;
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba($main-font-color, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background: $secondary;
      box-shadow: inset 0 0 6px rgba($main-font-color, 0.3);
    }
  }
  .slick-track {
    display: flex !important;
    .slick-slide:nth-of-type(1) {
      opacity: 0;
    }
    .slick-slide.slick-active + .slick-slide {
      opacity: 1;
    }
  }
  .slide-img {
    &-desktop {
      display: none;
      object-fit: cover;
      @include media-breakpoint-up(md) {
        display: block;
        width: 40%;
        height: 100%;
        min-height: 100%;
        float: left;
      }
    }
    &-mobile {
      display: block;
      width: 70%;
      height: auto;
      margin-top: 3rem;
      margin-left: auto;
      margin-right: auto;
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
  .slide-table {
    width: 100%;
    background-color: $hero-font-color;
    @include media-breakpoint-up(md) {
      padding: 3rem;
    }
    @include media-breakpoint-up(lg) {
      padding: 5rem;
    }
    .section-title {
      padding-top: 1rem;
      @include media-breakpoint-up(xl) {
        padding-top: 2rem;
      }
      @include media-breakpoint-up(xl) {
        font-size: 4.5rem;
      }
    }
  }
  .slide-content {
    width: 100%;
    padding: 2rem;
    background-color: $hero-font-color;
    @include media-breakpoint-up(md) {
      width: 60%;
      float: right;
    }
    @include media-breakpoint-up(xl) {
      padding: 4rem 4rem 4rem 6rem;
    }
    @media screen and (min-width: 1500px) {
      padding: 7rem 7rem 7rem 9rem;
    }
    .section-title {
      padding-right: 4rem;
      font-size: 2.5rem;
      @include media-breakpoint-up(lg) {
        padding-right: 0;
        font-size: 3rem;
      }
      @include media-breakpoint-up(xl) {
        font-size: 4rem;
      }
      @media screen and (min-width: 1790px) {
        font-size: 4.5rem;
      }
    }
  }
  .slick-slide {
    height: 70vh !important;
    background-color: $hero-font-color;
    overflow: auto;
  }
  .slide-simple {
    height: 100%;
    width: 100%;
  }
}
.custom-dots {
  .slick-dots {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  li {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2px;
    margin-right: 2px;
    button {
      display: block;
      box-sizing: border-box;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $border-light;
      color: transparent;
      font-size: 0;
      border: 0;
      outline: none;
      transform: scale(0.4);
      opacity: 0.7;
    }
    &.slick-active {
      button {
        opacity: 1;
        background-color: $hero-font-color;
        transform: scale(1);
      }
    }
  }
}
