.top-header {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  padding-top: 2rem;
  @include media-breakpoint-up(lg) {
    padding-top: 0;
  }
  &__navigation {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
      padding-left: 4rem;
      padding-right: 4rem;
    }
    &-mobile {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100vh;
      background-color: $primary;
      text-align: center;
      z-index: 15;
      overflow: auto;
      display: none;
      animation: show 0.5s;
      @include media-breakpoint-up(lg) {
        display: none;
      }
      .logo-wrapper {
        width: 100%;
        text-align: center;
        padding: 2rem;
        border-bottom: 3px solid $secondary;
      }
      .logo {
        display: flex;
        align-items: center;
      }
      .menu-mobile {
        margin: 6rem 0;
        width: 100%;
        padding: 0 2rem;

        li {
          margin-bottom: 4rem;
          a {
            font-size: 2.5rem;
            font-weight: bold;
            text-transform: uppercase;
            color: white;
          }
        }

        &__sublist {
          padding-top: 4rem;
          li {
            margin-bottom: 2rem;
            a {
              font-size: 1.8rem;
              text-transform: initial;
            }
          }
        }
      }
      .btn-wrapper {
        width: 100%;
        text-align: center;
        text-align: center;
        margin-top: 3rem;
        margin-bottom: 3rem;
        padding: 0 2.4rem;

        .btn {
          background-color: $white;
          color: $primary;
        }
      }
    }
  }
  &__menu-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 5.6rem 2rem;
    transition: $transition-time;

    display: none;

    @include media-breakpoint-up(lg) {
      display: flex;
    }
    li {
      a {
        color: $hero-font-color;
        margin: 0 2.2rem;
        text-align: center;
        text-decoration: none;
      }
    }
  }
  .btn-desktop {
    display: none;
    svg {
      path {
        fill: white;
      }
    }
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }
  &.header-fixed {
    position: fixed;
    z-index: 8;
    background-color: $primary;
    animation: show $transition-time ease;
    padding: 2rem;

    height: 8rem;
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
    .top-header__menu-list {
      padding: 3rem 2rem;
      li {
        a {
          opacity: 0.6;
          color: #fefeff;
          &.current-link {
            font-weight: 600;
            opacity: 1;
          }
        }
      }
    }
    .btn-desktop {
      color: $primary;
      background-color: $hero-font-color;
      svg {
        path {
          fill: $primary;
        }
      }
    }
    .mobile-menu-btn {
      span {
        background-color: white;
      }
    }
  }
  &.header-invisible {
    opacity: 0;
    pointer-events: none;
  }
}

.top-mobile {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.menu-btn-wrapper {
  position: fixed;
  top: 1.8rem;
  right: 1.8rem;
  z-index: 16;
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.mobile-menu-btn {
  width: 60px;
  height: 45px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  background-color: transparent;
  border: none;
  box-shadow: none;
  transform: scale(0.5);
  span {
    display: block;
    position: absolute;
    height: 9px;
    width: 100%;
    background-color: $primary;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 18px;
    }

    &:nth-child(4) {
      top: 36px;
    }
  }
  &.open {
    span {
      background-color: white;
    }
    span:nth-child(1) {
      top: 18px;
      width: 0%;
      left: 50%;
    }

    span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      top: 18px;
      width: 0%;
      left: 50%;
    }
  }
}
