html {
  font-size: 62.5%;
  font-family: $main-font;
  scroll-behavior: smooth;
  max-height: 100%;
}

body {
  font-size: 1.6rem;
  line-height: 1.2;
  max-height: 100%;
  &.popup-opened {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4 {
  font-weight: bold;
}

a,
button {
  transition: $transition-time;
}

.container {
  @media screen and (min-width: 1560px) {
    max-width: 1300px;
    padding-left: 5rem;
    padding-right: 5rem;
  }
  @media screen and (min-width: 1799px) {
    max-width: 1400px;
  }
}
.container-fluid {
  max-width: 1920px;
  @media screen and (min-width: 1299px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.fullwidth-section {
  @media screen and (min-width: 1920px) {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
}

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1.4rem 5rem 1.2rem 2.8rem;
  border-radius: 50px;
  font-size: 1.5rem;
  font-weight: 600;
  &--icon,
  &--cta {
    svg {
      position: absolute;
      top: 50%;
      right: 3rem;
      transform: translateY(-50%);
      display: inline-block;
      width: 10px;
      height: 10px;
      transition: $transition-time;
    }
    &:hover {
      background-color: $primary;
      svg {
        right: 2rem;
      }
    }
  }
  &--cta {
    margin-top: 5rem;
    color: $hero-font-color;
    background-color: $textlight2;
    &:hover {
      color: $hero-font-color;
    }
  }
}

.section-title {
  margin-bottom: 3rem;
  color: $primary;
  font-size: 3.5rem;
  line-height: 1.2;
  font-weight: 600;
  @include media-breakpoint-up(md) {
    font-size: 5rem;
  }
}

.text-intro {
  margin-bottom: 4.5rem;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.55;
}

.list-simple {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2;
  li {
    position: relative;
    padding-left: 3rem;
    width: 100%;
    @media screen and (min-width: 1799px) {
      padding-left: 4rem;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0.8rem;
      width: 15px;
      height: 15px;
      background-image: url(../img/checked.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &--half {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 100%;
      @include media-breakpoint-up(md) {
        width: 50%;
      }
      @include media-breakpoint-up(lg) {
        width: 35%;
      }
    }
  }
}

.img-bordered {
  border-radius: 20px;
}

.showed {
  opacity: 1 !important;
  animation: showAgain 1s;
}

.hidden {
  opacity: 0 !important;
  animation: dissapear 1s;
  pointer-events: none;
}

.scroll-anchor {
  position: absolute;
  pointer-events: none;
  width: 100%;
  z-index: -1;
  height: 100%;
  &--funct {
    top: -4rem;
    &-first {
      top: -15rem;
    }
    &-extra {
      top: -20rem;
    }
  }
}

.table {
  width: 100%;
  max-width: 100%;
  border: 1px solid $textlight2;
  overflow: auto;
  border-collapse: collapse;
  margin-top: 5rem;
  @include media-breakpoint-up(xl) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  tr {
    td:first-of-type {
      font-weight: 600;
    }
  }
  tr:nth-of-type(odd) {
    background: $border-light;
  }
  th {
    background-color: $primary;
    color: white;
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    border: 1px solid $textlight2;
    @include media-breakpoint-up(md) {
      padding: 1.5rem;
      font-size: 2rem;
    }
  }
  td {
    padding: 0.5rem;
    font-size: 1.3rem;
    border: 1px solid $textlight2;
    text-align: center;
    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
      padding: 1.5rem;
    }
  }
}

.logo {
  img {
    height: 3.2rem;
  }
}

#back-top {
  position: fixed;
  bottom: 8rem;
  right: 2rem;
  left: auto;
  z-index: 16;
  border-radius: 50%;
  background-color: $secondary;
  transition: $transition-time;
  &:hover {
    background-color: $primary;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
}
