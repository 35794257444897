@keyframes drawingLine {
  from {
    left: -100%;
    opacity: 0.6;
  }
  to {
    left: 0;
    opacity: 0.7;
  }
}

@keyframes dissapear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes showAgain {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes show {
  from {
    opacity: 0;
    background: $secondary;
  }
  to {
    opacity: 1;
    background: $primary;
  }
}
