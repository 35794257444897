.f-wrap {
  position: absolute;
  pointer-events: none;
  width: 15px;
  height: 15px;
  opacity: 0.7;

  @include media-breakpoint-up(md) {
    width: 20px;
    height: 20px;
    z-index: 2;
  }
  @media screen and (min-width: 1760px) {
    width: 30px;
    height: 30px;
  }
  .f {
    width: 15px;
    height: 15px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    @include media-breakpoint-up(md) {
      width: 20px;
      height: 20px;
    }
    @media screen and (min-width: 1760px) {
      width: 30px;
      height: 30px;
    }
  }
}

.pattern-ill {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: transparent;
  &--1 {
    .f {
      &-1-wrap {
        top: -30%;
        right: 0;
      }
      &-1 {
        transform: rotate(290deg);
      }
      &-2-wrap {
        top: 8%;
        left: -42%;
      }
      &-2 {
        transform: rotate(380deg) scale(0.9);
      }
    }
  }
  &--2 {
    .f {
      &-1-wrap {
        top: -20%;
        right: -84%;
      }
      &-1 {
        transform: rotate(312deg);
      }

      &-2-wrap {
        top: -14%;
        left: 37%;
      }
      &-2 {
        transform: rotate(360deg) scale(0.9);
      }
      &-3-wrap {
        top: 47%;
        left: 0;
      }
      &-3 {
        transform: rotate(150deg) scale(0.8);
      }
      &-4-wrap {
        top: 25%;
        right: 0;
      }
      &-4 {
        transform: rotate(380deg) scale(0.9);
      }
    }
  }
  &--3 {
    .f {
      &-1-wrap {
        top: 20%;
        right: -24%;
      }
      &-1 {
        transform: rotate(145deg) scale(0.8);
      }
      &-2-wrap {
        top: -43%;
        left: 45%;
        z-index: -1;
      }
      &-2 {
        transform: rotate(360deg) scale(0.9);
      }
      &-3-wrap {
        top: 78%;
        left: 65%;
      }
      &-3 {
        transform: scale(0.3);
      }
      &-4-wrap {
        top: 0;
        left: -34%;
      }
      &-4 {
        transform: rotate(342deg) scale(0.9);
      }
    }
  }
  &--4 {
    .f {
      &-1-wrap {
        top: -34%;
        right: -3%;
      }
      &-1 {
        transform: rotate(145deg) scale(0.8);
      }

      &-2-wrap {
        top: 60%;
        left: -19%;
      }
      &-2 {
        transform: rotate(360deg) scale(1);
      }
      &-3-wrap {
        top: 11%;
        right: -42%;
      }
      &-3 {
        transform: rotate(90deg) scale(1);
      }
    }
  }
  &--5 {
    .f {
      &-1-wrap {
        top: -34%;
        right: -3%;
      }
      &-1 {
        transform: rotate(312deg);
      }
      &-2-wrap {
        top: 44%;
        right: -15%;
      }
      &-2 {
        transform: rotate(340deg);
      }
      &-3-wrap {
        top: 11%;
        left: -35%;
      }
      &-3 {
        transform: rotate(312deg) scale(0.8);
      }
      &-4-wrap {
        top: 64%;
        left: -35%;
      }
      &-4 {
        transform: rotate(312deg);
      }
    }
  }
  &--6 {
    .f {
      &-1-wrap {
        top: 55%;
        right: -45%;
      }
      &-1 {
        transform: rotate(312deg);
      }
      &-2-wrap {
        top: 0;
        left: -220%;
      }
      &-2 {
        transform: rotate(340deg);
      }
      &-3-wrap {
        top: 2%;
        right: -58%;
      }
      &-3 {
        transform: rotate(308deg) scale(0.7);
      }
      &-4-wrap {
        top: 62%;
        left: -19%;
      }
      &-5-wrap {
        top: 2%;
        right: 19%;
      }
      &-5 {
        transform: scale(0.3);
      }
    }
  }
}

.pattern {
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  background-color: transparent;
  pointer-events: none;

  &--hero {
    z-index: -1;
    height: 100%;
    top: 0;
    transition: opacity 0.25s ease-in;

    .f {
      &-1-wrap {
        top: 40%;
        right: 10%;
      }
      &-1 {
        transform: rotate(180deg);
      }
      &-2-wrap {
        top: 70%;
        right: 4%;
      }
      &-2 {
        transform: rotate(380deg) scale(1.1);
      }
      &-3-wrap {
        top: 60%;
        right: 13%;
      }
      &-3 {
        transform: rotate(-10deg) scale(0.95);
      }
      &-4-wrap {
        top: 15%;
        left: 13%;
      }
      &-4 {
        transform: scale(0.8);
      }
      &-5-wrap {
        top: -7px;
        left: 22%;
      }
      &-6-wrap {
        top: 33%;
        left: 2%;
      }
      &-6 {
        transform: rotate(-10deg);
      }
      &-7-wrap {
        top: 60%;
        left: -5px;
      }
      &-7 {
        transform: rotate(-45deg);
      }
      &-8-wrap {
        top: 10%;
        left: 28%;
      }
      &-8 {
        transform: rotate(-45deg);
      }
      &-9-wrap {
        top: 44%;
        left: 8%;
      }
      &-9 {
        transform: scale(0.4);
      }
      &-10-wrap {
        top: 4%;
        left: 80%;
      }
      &-11-wrap {
        top: 25%;
        left: 60%;
      }
      &-11 {
        transform: scale(1.1);
      }
      &-12-wrap {
        top: 50%;
        left: 62%;
      }
      &-12 {
        transform: scale(0.9);
      }
      &-13-wrap {
        top: 50%;
        right: -8px;
      }
      &-13 {
        transform: scale(0.9);
      }
      &-14-wrap {
        top: 22%;
        right: 4%;
      }
      &-15-wrap {
        top: 23%;
        left: 8%;
      }
      &-15 {
        transform: scale(0.7);
      }
    }
  }
  &--about {
    height: 100%;
    top: 0;
    .f {
      &-1-wrap {
        top: 60%;
        left: 7%;
      }
      &-1 {
        transform: rotate(80deg);
      }
      &-2-wrap {
        top: 20%;
        right: 10%;
      }
      &-2 {
        transform: rotate(275deg);
      }
      &-3-wrap {
        top: 16%;
        left: 6%;
        z-index: 10;
      }
      &-3 {
        transform: scale(0.6);
      }
      &-4-wrap {
        top: 6%;
        left: 6%;
      }
      &-4 {
        transform: scale(0.3);
      }
      &-5-wrap {
        top: 36%;
        left: 16%;
      }
      &-5 {
        transform: scale(0.4);
      }
      &-6-wrap {
        top: 48%;
        right: 27%;
      }
      &-7-wrap {
        top: 5%;
        left: 14%;
      }
      &-7 {
        transform: rotate(128deg);
      }
      &-8-wrap {
        top: 40%;
        right: 10%;
      }
      &-8 {
        transform: rotate(128deg) scale(1.1);
      }
      &-9-wrap {
        top: 44%;
        left: 45%;
      }
      &-9 {
        transform: scale(0.8);
      }
      &-10-wrap {
        top: 3%;
        right: 10%;
      }
      &-10 {
        transform: scale(0.6);
      }
      &-11-wrap {
        top: 5%;
        left: 60%;
      }
      &-11 {
        transform: scale(0.9);
      }
      &-12-wrap {
        top: 40%;
        left: 40%;
      }
      &-12 {
        transform: scale(0.9);
      }
      &-13-wrap {
        top: 73%;
        left: 55%;
        z-index: 10;
      }
      &-13 {
        transform: scale(0.8) rotate(180deg);
      }
    }
  }

  &--studio {
    position: absolute;
    z-index: 2;
    height: 160%;
    top: -20%;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    .f {
      &-1-wrap {
        top: 20%;
        left: 16%;
      }
      &-1 {
        transform: rotate(-180deg);
      }
      &-2-wrap {
        top: 74%;
        left: 0%;
      }
      &-2 {
        transform: rotate(-200deg);
      }
      &-3-wrap {
        left: -6%;
        top: -13%;
      }
      &-3 {
        transform: scale(0.8) rotate(-50deg);
      }
      &-4-wrap {
        top: 41%;
        left: 52%;
      }
      &-4 {
        transform: rotate(170deg);
      }
      &-5-wrap {
        top: 30%;
        right: 20%;
      }
      &-5 {
        transform: rotate(-220deg);
      }
      &-6-wrap {
        top: 44%;
        right: 10%;
      }
      &-6 {
        transform: rotate(170deg);
      }
    }
  }
  &--footer {
    height: 100%;
    top: 0;
    pointer-events: none;
    .f {
      &-1-wrap {
        top: 20%;
        left: 10%;
      }
      &-1 {
        transform: rotate(220deg);
      }
      &-2-wrap {
        top: 0;
        left: 75%;
      }
      &-2 {
        transform: rotate(150deg) scale(1.1);
      }
      &-3-wrap {
        top: 50%;
        right: 30%;
      }
      &-3 {
        transform: rotate(-20deg) scale(0.95);
      }
      &-4-wrap {
        top: 20%;
        left: 8%;
      }
      &-4 {
        transform: scale(0.8) rotate(45deg);
      }
      &-5-wrap {
        top: 30%;
        left: 20%;
      }
      &-5 {
        transform: rotate(-45deg);
      }
      &-6-wrap {
        top: 3%;
        left: 65%;
      }
      &-6 {
        transform: rotate(-45deg) scale(0.8);
      }
      &-7-wrap {
        top: 40%;
        right: 8%;
      }
      &-7 {
        transform: scale(0.4);
      }
      &-8-wrap {
        top: 7%;
        left: 80%;
      }
      &-8 {
        transform: rotate(180deg);
      }
      &-9-wrap {
        top: 30%;
        left: 22%;
      }
      &-9 {
        transform: scale(1.1);
      }
      &-10-wrap {
        top: 30%;
        left: 9%;
      }
      &-10 {
        transform: scale(0.8) rotate(180deg);
      }
    }
  }
  &--clients {
    height: 100%;
    top: 0;
    pointer-events: none;
    .f {
      &-1-wrap {
        top: 10%;
        right: 5%;
      }
      &-1 {
        transform: rotate(60deg);
      }
      &-2-wrap {
        top: 0;
        left: 70%;
      }
      &-2 {
        transform: rotate(125deg);
      }
      &-3-wrap {
        top: 20%;
        left: 15%;
      }
      &-3 {
        transform: scale(0.7);
      }
    }
  }
}

.extra {
  .f {
    &-1-wrap {
      top: 16%;
      right: 15%;
      z-index: 5;
    }
    &-2-wrap {
      top: 62%;
      right: 9%;
    }
    &-2 {
      transform: rotate(80deg) scale(1.1);
    }
    &-3-wrap {
      left: 4%;
      top: 40%;
    }
    &-3 {
      transform: scale(0.8);
    }
    &-4-wrap {
      top: 28%;
      left: 18%;
    }
    &-4 {
      transform: rotate(250deg);
    }
  }
}
