.footer {
  position: relative;
  padding-top: 7.5rem;
  &__top {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: $bcg-dark;
    text-align: center;
    @include media-breakpoint-up(md) {
      padding-top: 9.5rem;
      padding-bottom: 11.5rem;
    }
    .text-intro {
      color: $hero-font-color;
    }
    .btn {
      margin-bottom: 4rem;
    }
    .footer-text {
      color: $hero-font-color;
      a {
        color: $red;
      }
    }
  }
  &__bottom {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #19181f;
    @include media-breakpoint-up(lg) {
      padding-top: 9rem;
      padding-bottom: 9rem;
    }
  }
  &__menu-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: flex-start;
    }

    li {
      a {
        margin: 0 4.4rem 0 0;
        color: $hero-font-color;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.4;
        @include media-breakpoint-up(lg) {
          line-height: unset;
        }
        &:hover {
          color: $secondary;
        }
      }
    }
  }
  &__navigation {
    margin-top: 7rem;
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }
  .logo {
    display: inline-block;
    width: 100%;
    @include media-breakpoint-up(xl) {
      width: auto;
    }
    img {
      width: 14rem;
      height: 4rem;
    }
  }
}

.copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: $textlight;
  a {
    position: relative;
    color: white;
  }
  span {
    display: block;
    margin: 1rem 0;
    @include media-breakpoint-up(lg) {
      display: inline-block;
      margin: 0;
    }
  }
}

.socials {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 4.2rem;
  margin-bottom: 4.2rem;
  @include media-breakpoint-up(xl) {
    justify-content: flex-start;
    margin-bottom: 0;
  }
  .socials__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 3.3rem;
    margin-bottom: 2rem;
    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
    }
    a {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      color: $hero-font-color;
    }
    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 2rem;
      transition: $transition-time;
    }
    &:first-of-type {
      .icon-wrapper {
        background-color: $red;
      }
    }
    &:nth-of-type(2) {
      .icon-wrapper {
        background-color: $primary;
      }
    }
    &:nth-of-type(3) {
      .icon-wrapper {
        background-color: $violet;
      }
    }
    img {
      width: 20px;
      height: 18px;
    }
    &:hover {
      &:first-of-type {
        a {
          color: $red;
        }

        .icon-wrapper {
          background-color: darken($red, 10%);
        }
      }
      &:nth-of-type(2) {
        a {
          color: $primary;
        }
        .icon-wrapper {
          background-color: darken($primary, 10%);
        }
      }
      &:nth-of-type(3) {
        a {
          color: $violet;
        }
        .icon-wrapper {
          background-color: darken($violet, 10%);
        }
      }
    }
  }
}

.footer-boxes {
  .footer-col-big {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    &--first {
      @include media-breakpoint-up(lg) {
        justify-content: flex-start;
      }
    }
    &--last {
      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
      }
    }
  }
  .footer-box-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    address {
      text-align: center;
      padding-left: 1rem;
      padding-right: 1rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
      @include media-breakpoint-up(xl) {
        text-align: right;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
      }
      &:nth-of-type(2) {
        @include media-breakpoint-up(md) {
          margin-left: 5rem;
        }
        @media screen and (min-width: 1799px) {
          margin-left: 9.5rem;
        }
      }
      span {
        display: block;
        color: $hero-font-color;
        line-height: 1.53;
      }
      a {
        color: $red;
      }
    }
  }
}

.adress-icon {
  width: 50px;
  height: 30px;
  margin-bottom: 2.3rem;
}
.address-title {
  font-weight: 600;
}

.cookie-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: $hero-font-color;
  color: $textlight;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.25;
  a {
    color: $textlight;
    text-decoration: underline;
  }
}
