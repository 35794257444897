$main-font: "Gilroy";
$main-font-color: #0e0d13;

$primary: #2154ff;
$secondary: #02d5aa;
$hero-font-color: #ffffff;

$red: #ed604d;
$bcg-dark: #09080c;
$bcg-dark2: #2e2b39;
$box-solor: #23222b;

$violet: #7521ff;
$textlight: #878490;
$textlight2: #b7bbd8;
$textlight3: #b4b4b6;

$border-light: #f0f0f5;

$transition-time: 0.3s;
